<div class="botao-imprimir">
    <h2>Ingressos</h2>
    <p-button (onClick)="imprimirComponente()">Imprimir</p-button>
</div>

<div *ngIf="!dataSource.hasError()"  class="container" id="componenteParaImprimir" #componenteParaImprimir>
    <div  *ngFor="let item of ingressos" class="credencial-container">
        <img src="../../../../../assets/img/bfs-logo.png" alt="Credencial" width="50" />
        <span>Este é seu ingresso para acesso ao Bahia Farm Show, apresente-o na bilheteria e aproveite a feira!</span>
        <svg width="24" height="24" style="z-index: 9997;" id="barcode-{{item.id}}" #barcode></svg>
        <span class="bold data-validade-ingresso">Válido dia: {{item.dataInicio | date: 'dd/MM/yyyy'}}</span>
        <div>
            <span class="website">www.wiesoo.com</span>
            <span class="bold">Tecnologia e Inovação Para o Agro!</span>
        </div>
    </div>
</div>