import { Injectable, inject } from '@angular/core';
import { ListaPaginada } from 'src/app/shared/models/lista-paginada.model';
import { Pesquisa } from 'src/app/shared/models/pesquisa.model';
import { TabeBaseService } from 'src/app/shared/models/table-base.service';
import { environment } from 'src/environments/environment';
import { API_DASHBOARD_ROUTES } from '../../api/api-routes';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ListarIngressoResponse } from '../../api/response/Listar-ingresso.response';

@Injectable({
  providedIn: 'root'
})
export class DashboardImpressaoIngressoService implements TabeBaseService<any> {

  private http = inject(HttpClient);

  loadData(pesquisa: Pesquisa): Promise<ListaPaginada<ListarIngressoResponse>> {
    return this.listarTodasVendasIngressosPorUsuario(pesquisa);
  }
  updateItem() {
    throw new Error('Method not implemented.');
  }
  removeItem() {
    throw new Error('Method not implemented.');
  }

  public async listarTodasVendasIngressosPorUsuario(pesquisa: Pesquisa): Promise<ListaPaginada<ListarIngressoResponse>> {
    const apiUrl = environment.api + API_DASHBOARD_ROUTES.buscarVendasIngresso();
    let params = new HttpParams();
    params = params.append("NumeroPagina", pesquisa.numeroPagina);
    params = params.append("TamanhoPagina", pesquisa.tamanhoPagina);
    if (pesquisa.ascDes)
      params = params.append("AscDesc", pesquisa.ascDes);
    if (pesquisa.reciboVendaId)
      params = params.append("ReciboVendaId", pesquisa.reciboVendaId);
    return await lastValueFrom(this.http.get<ListaPaginada<ListarIngressoResponse>>(apiUrl, { params }));;
  }
}
