import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, inject } from '@angular/core';
import { ListarIngressoResponse } from '../../api/response/Listar-ingresso.response';
import * as JsBarcode from 'jsbarcode';
import { DataSource } from 'src/app/shared/models/datasource';
import { DashboardImpressaoIngressoService } from './dashboard-impressao-ingresso.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-impressao-ingresso',
  templateUrl: './impressao-ingresso.component.html',
  styleUrls: ['./impressao-ingresso.component.scss']
})
export class ImpressaoIngressoComponent implements OnInit {

  @ViewChild('componenteParaImprimir', { static: false }) componenteParaImprimir!: ElementRef;
  @ViewChildren('barcode') barcodeElements!: QueryList<ElementRef>;

  private service = inject(DashboardImpressaoIngressoService);
  private router = inject(ActivatedRoute);
  public dataSource = new DataSource<ListarIngressoResponse>(this.service);

  public ingressos: ListarIngressoResponse[] = [
    // Adicione mais ingressos conforme necessário
  ];

  async ngOnInit(): Promise<void> {
    let id;
    this.router.paramMap.subscribe(params => {
      id = params.get('id');
    });
    if (id) {

      this.dataSource.pesquisa = {
        numeroPagina: 1,
        tamanhoPagina: 100,
        ascDes: 1,
        reciboVendaId: id
      }
    }
    await this.dataSource.loadData();

    this.formatarIngressos();
  }

  private formatarIngressos() {
    this.ingressos = [];
    this.dataSource.data()?.itens.forEach((item, index) => {
      this.ingressos.push(item)
    })
  }

  public imprimirComponente() {
    const styles = `
      .botao-imprimir {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
}

.container {
    position: relative;
    width: 21cm;
    border: 1px solid black;
    margin-top: 4rem;
    margin-bottom: 2rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.credencial-container {
    width: 6cm;
    height: 9cm;
    margin-bottom: 0.9cm;
    border: 1px solid black;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    padding: 2rem;
    gap: 8px;

    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.website {
    font-size: 1.2rem;
}

.data-validade-ingresso {
    font-size: 1.2rem;
}

.bold {
    font-weight: bold;
}

span{
    text-align: center;
}

@media print {
    .container {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: space-around !important;
        width: 21cm !important;
        margin: auto !important;
    }

    .credencial-container {
        width: 6cm !important;
        height: 9cm !important;
        margin-bottom: 0.9cm !important;
        border: 1px solid black !important;
        display: flex !important;
        align-items: center !important;
        flex-direction: column !important;
        border-radius: 4px !important;
        padding: 2rem !important;
        gap: 8px !important;
        page-break-inside: avoid !important; /* Evita quebra de página no meio de um ingresso */
    }
}
    `;

    const conteudoComponente = this.componenteParaImprimir.nativeElement.innerHTML;

    const janelaImprimir = window.open('', '', 'height=600,width=800');
    janelaImprimir!.document.write('<html><head><title>Imprimir Relatório</title>');
    janelaImprimir!.document.write('<style>' + styles + '</style>');
    janelaImprimir!.document.write('</head><body>');
    janelaImprimir!.document.write('<div class="container">' + conteudoComponente + '</div>');
    janelaImprimir!.document.write('</body></html>');
    janelaImprimir!.document.close();
    janelaImprimir!.addEventListener("load", () => {
      janelaImprimir!.print();
    });
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      this.barcodeElements.forEach((barcodeElement, index) => {
        const ingresso = this.dataSource.data()?.itens[index];
        JsBarcode(barcodeElement.nativeElement, ingresso!.codigo);
      });
    }, 2000)
  }
}
