import { NgZone } from '@angular/core';
import { Form, FormArray, FormGroup } from '@angular/forms';
import { Observable, OperatorFunction } from 'rxjs';
import { FileDownload } from './models/file-download.model';
import { HttpErrorResponse } from '@angular/common/http';

export function toInteger(value: any): number
{
    return parseInt(`${value}`, 10);
}

export function toString(value: any): string
{
    return (value !== undefined && value !== null) ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number
{
    return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string
{
    return typeof value === 'string';
}

export function isNumber(value: any): value is number
{
    return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number
{
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isDefined(value: any): boolean
{
    return value !== undefined && value !== null;
}

export function isPromise<T>(v: any): v is Promise<T>
{
    return v && v.then;
}

export function padNumber(value: number)
{
    if (isNumber(value))
    {
        return `0${value}`.slice(-2);
    } else
    {
        return '';
    }
}

export function regExpEscape(text: string)
{
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean
{
    return element && element.className && element.className.split &&
        element.className.split(/\s+/).indexOf(className) >= 0;
}

export function closest(element: HTMLElement, selector?: string): HTMLElement | null
{
    if (!selector)
    {
        return null;
    }

    /*
     * In certain browsers (e.g. Edge 44.18362.449.0) HTMLDocument does
     * not support `Element.prototype.closest`. To emulate the correct behaviour
     * we return null when the method is missing.
     *
     * Note that in evergreen browsers `closest(document.documentElement, 'html')`
     * will return the document element whilst in Edge null will be returned. This
     * compromise was deemed good enough.
     */
    if (typeof element.closest === 'undefined')
    {
        return null;
    }

    return element.closest(selector);
}

/**
 * Force a browser reflow
 * @param element element where to apply the reflow
 */
export function reflow(element: HTMLElement)
{
    return (element || document.body).getBoundingClientRect();
}

/**
 * Creates an observable where all callbacks are executed inside a given zone
 *
 * @param zone
 */
export function runInZone<T>(zone: NgZone): OperatorFunction<T, T>
{
    return (source) =>
    {
        return new Observable(observer =>
        {
            const next = (value: T) => zone.run(() => observer.next(value));
            const error = (e: any) => zone.run(() => observer.error(e));
            const complete = () => zone.run(() => observer.complete());
            return source.subscribe({ next, error, complete });
        });
    };
}

export function removeAccents(str: string): string
{
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function tryRun<T>(callback: Function): [T | null, any]
{
    try
    {
        const result = callback();
        return [result as T, null];
    }
    catch (e: any)
    {
        return [null, e];
    }
}

export async function tryRunPromise<T>(promise: Promise<T>): Promise<[T | null, any]>
{
    try
    {
        const response = await promise as T;
        return [response, null];
    }
    catch (e: any)
    {
        return [null, e ];
    }
}

export function markAsDirtyAndTouched(form: FormGroup, fieldName: string)
{
    const field = form.get(fieldName);

    if (!isDefined(field))
        return;

    field?.markAsDirty();
    field?.markAsTouched();
}

export function markAllAsDirtyAndTouched(form: FormGroup)
{
    if (!isDefined(form))
        return;

    Object.keys(form.controls).forEach(key =>
    {
        const control = form.get(key);
        if (control instanceof FormGroup)
            markAllAsDirtyAndTouched(control);

        if (control instanceof FormArray)
            (control as FormArray).controls.forEach(c => markAllAsDirtyAndTouched(c as FormGroup))

        form.controls[key].markAsDirty();
        form.controls[key].markAsTouched();
    });
}

export function converterBase64ParaBlob(base64: string)
{
    const byteString = window.atob(base64);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++)
    {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: 'application/octet-stream' });
}

export function downloadFile(file: FileDownload)
{
    const blob = converterBase64ParaBlob(file.fileContents);
    const download = document.createElement('a');
    download.href = window.URL.createObjectURL(blob);
    download.setAttribute("download", file.fileDownloadName);
    document.body.appendChild(download);
    download.click();
}