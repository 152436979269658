<h1>Credenciais</h1>
<h2>Selecione os dias:</h2>
<div class="vendas-container">
    <div class="dias-container">
        <ng-container *ngFor="let dia of listaDeIngressos; let i = index">
            <div [ngStyle]="{'background-color': dia.ativo ? '#8aff00' : '#a167ff'}" (click)="toggleDia(dia.id)">
                <h3>{{i + 1}}º dia</h3>
                <div>
                    <span>{{dia.dataInicio| date: 'dd/MM/yyyy'}}</span>
                    <span>{{dia.preco| currency:'BRL':'symbol':'1.2-2'}}</span>
                    <div *ngIf="dia.ativo">
                        <input type="number" [value]="dia.quantidade" pattern="[0-9]*"
                            (input)="updateQuantidade($event, i)" (click)="handleChildClick($event)">
                    </div>
                </div>
            </div>
        </ng-container>
    </div><br />
    <div class="total">
        <div class="container-valor-total">
            <span>Total: </span>
            <span class="valor">{{valorTotal | currency:'BRL':'symbol':'1.2-2'}}</span>
        </div>
        <div class="container-botao-comprar">
            <p-button label="Comprar" pRipple [loading]="loading()" loadingIcon="pi pi-spin pi-spinner"
                [disabled]="loading()" (onClick)="abrirModalConfirmacao()"></p-button>
        </div>
    </div>
</div>
<ng-container *ngIf="!dataSource.isLoading() && !dataSource.hasError(); else carregando">
    <p-table [value]="dataSource.data()?.itens!" (onSort)="customSort($event)">
        <ng-template pTemplate="header">
            <tr>

                <th pSortableColumn="data">Visualizar</th>
                <th pSortableColumn="sankhyaId">Status</th>
                <th pSortableColumn="sankhyaId" class="text-center">Valor Total Carrinho</th>
                <th pSortableColumn="sankhyaId">Data da Criação</th>
                <th pSortableColumn="sankhyaId">Data do Pagamento</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ingresso>
            <tr>
                <td>
                    <svg *ngIf="ingresso.statusPagamento === 'Pendente'; else visualizarIngresso"
                        (click)="abrirModalPix(ingresso.pixCopiaECola, ingresso.expiracao, ingresso.valorTotalCompra, ingresso.dataCriacao)"
                        class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-width="2"
                            d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                        <path stroke="currentColor" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                    <ng-template #visualizarIngresso>
                        <svg *ngIf="ingresso.statusPagamento === 'Aprovado'"
                            (click)="navegarParaPaginaComId(ingresso.id)" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 6C7 4.89543 7.89543 4 9 4H15C16.1046 4 17 4.89543 17 6V8H7V6Z" stroke="#000000"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7 14H17V18C17 19.1046 16.1046 20 15 20H9C7.89543 20 7 19.1046 7 18V14Z"
                                stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18 17H21V10C21 8.89543 20.1046 8 19 8H5C3.89543 8 3 8.89543 3 10V17H6"
                                stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18 11L16 11" stroke="#000000" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </ng-template>
                </td>
                <td>
                    <h3>{{ingresso.statusPagamento}}</h3>
                    <p>{{ingresso.descricao}}</p>
                </td>
                <td class="text-center">R$ {{ingresso.valorTotalCompra}}</td>
                <td>{{ingresso.dataCriacao | date:'short'}}</td>
                <td>{{ingresso.dataPagamento | date:'short'}}</td>
            </tr>
        </ng-template>
    </p-table>
    <div class="paginator" *ngIf="dataSource.data()">
        <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="dataSource.numberOfRows()"
            [totalRecords]="dataSource.data()?.totalItens!" [rowsPerPageOptions]="dataSource.rowsPerPage">
        </p-paginator>
    </div>
</ng-container>

<ng-template #carregando>
    <p-table [value]="[1, 2]">
        <ng-template pTemplate="header">
            <tr>
                <th>Nome</th>
                <th>CPF</th>
                <th>Empresa</th>
                <th>Validade</th>
                <th>Categoria</th>
                <th>Data</th>
                <th><i class="pi pi-ellipsis-h"></i></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-usuario>
            <tr>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
            </tr>
            <tr>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
                <td><app-shimmer></app-shimmer></td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>

<p-toast></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-dialog header="Chave PIX" [(visible)]="displayDialog" [modal]="true" class="pix-dialog" (onHide)="hideDialog()">
    <p class="info-text">O QR Code só funcionará se o pagamento ainda não tiver sido efetuado.<br />
        Em caso de erro, pode ser que o pagamento já tenha sido realizado ou o prazo tenha expirado.</p>
    <div class="qr-code-container">
        <qrcode [qrdata]="qrCodePix" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    <div class="pix-details">
        <p>Data da Compra: {{ dataCompra | date:'short' }}</p>
        <p>Expiração: {{ expiracao | date:'short' }}</p>
        <p style="color: #2eb82e; margin-top: 3rem; margin-bottom: 3rem;" class="color-green bolder mt4">VALOR TOTAL: {{
            valorTotalResponse | currency:'BRL':'symbol':'1.2-2' }}</p>

    </div>
    <p-button icon="pi pi-copy" (click)="copyQrCodePix()" label="Copiar chave PIX" class="copy-button"></p-button>
</p-dialog>

<p-dialog header="Carrinho de Compras" [(visible)]="displayConfirmationDialog" [modal]="true"
    class="confirmation-dialog">
    <ul *ngIf="ingressosSelecionados.length > 0">
        <li style="padding: 1.2rem;" *ngFor="let ingresso of ingressosSelecionados; let i = index">
            <span>{{ ingresso.dataInicio | date: 'dd/MM/yyyy' }} - {{ ingresso.descricao }}</span>
            <span>{{ ingresso.quantidade }} ingresso(s)</span>
        </li>
    </ul>
    <p *ngIf="ingressosSelecionados.length === 0">Nenhum ingresso selecionado.</p>
    <div class="total-container" *ngIf="ingressosSelecionados.length > 0">
        <span>Total: </span>
        <span class="color-green">{{ valorTotal | currency:'BRL':'symbol':'1.2-2' }}</span>
    </div>
    <div class="action-buttons">
        <p-button label="Confirmar" icon="pi pi-check" (click)="confirmarCompra()" class="ui-button-success"></p-button>
        <p-button label="Cancelar" icon="pi pi-times" (click)="displayConfirmationDialog=false"
            class="ui-button-secondary"></p-button>
    </div>
</p-dialog>