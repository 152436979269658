import { Component, OnInit, inject } from '@angular/core';
import { tryRunPromise } from 'src/app/shared/functions';
import { DashboardService } from './dashboard.service';
import { DataSource } from 'src/app/shared/models/datasource';
import { IngressosResponse, ListaVendasResponse } from './api/response/buscar-ingresso.response';
import { SortEvent } from 'primeng/api';
import { PaginatorState } from 'primeng/paginator';
import { ToastService } from 'src/app/shared/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { VendasResponse } from './api/response/buscar-venda.response';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  private service = inject(DashboardService);
  private router = inject(Router);
  public dataSource = new DataSource<VendasResponse>(this.service);
  public listaDeIngressos: IngressosResponse[] = []
  public ingressosSelecionados: IngressosResponse[] = []
  public first: number = 1;
  public qrCodePix: string = "Inválido";
  private toastService = inject(ToastService);
  public expiracaoEmSegundos: number = 0;
  public expiracao!: Date;
  public dataCompra!: Date;
  public valorTotal!: number;
  public valorTotalResponse!: number;
  public displayDialog: boolean = false;
  public displayConfirmationDialog: boolean = false;

  public loading() {
    return false;
  }

  async customSort(event: SortEvent) {
    this.dataSource.pesquisa = {
      ...this.dataSource.pesquisa,
      nomeColuna: event.field,
      ascDes: event.order
    }
    await this.dataSource.loadData()
  }

  async onPageChange(event: PaginatorState) {
    this.first = event.first!;
    const pesquisaAtual = this.dataSource.pesquisa;
    this.dataSource.pesquisa = {
      ...pesquisaAtual,
      numeroPagina: event.page! + 1,
      tamanhoPagina: event.rows!
    }
    this.dataSource.updateNumberRows(event.rows!)
    await this.dataSource.loadData();
  }

  async ngOnInit(): Promise<void> {
    await this.buscarIngressos();

    this.dataSource.loadData();

    this.valorTotal = 0;

    this.alteraValorTotal();
  }

  navegarParaPaginaComId(id: string): void {
    this.router.navigate(['imprimir', id]);
  }

  private async buscarIngressos(): Promise<void> {
    const [response, error] = await tryRunPromise(this.service.buscarIngressos());
    if (error) return;
    if (response) this.listaDeIngressos = response;
  }

  public toggleDia(id: string) {
    this.listaDeIngressos.forEach(item => {
      if (item.id == id) {
        item.ativo = !item.ativo;
          item.ativo ? item.quantidade = 1: item.quantidade = 0;        
      }
    })
    this.alteraValorTotal();
  }
  handleChildClick(event: Event) {
    event.stopPropagation(); // Impede a propagação do evento de clique
  }

  private alteraValorTotal() {
    this.valorTotal = 0;
    const valoresComQuantidade: number[] = [];
    this.listaDeIngressos.forEach(item => {
      if (item.ativo) {
        // this.valorTotal += item.valor;
        valoresComQuantidade.push(item.preco * item.quantidade);
      }
    })
    this.valorTotal = valoresComQuantidade.reduce((acc, item) => acc + item, 0)
  }

  showDialog() {
    this.displayDialog = true;
  }

  hideDialog() {
    this.displayDialog = false;
    this.qrCodePix = "Inválido";
    this.expiracaoEmSegundos = 0;
    this.dataCompra = new Date;
    this.calcularExpiracao();
  }

  abrirModalPix(qrCode: string, expiracao: number, valorTotalResponse: number, dataCompra: string) {
    this.valorTotalResponse = valorTotalResponse;
    this.qrCodePix = qrCode;
    this.expiracaoEmSegundos = expiracao;
    this.dataCompra = new Date(dataCompra);
    this.calcularExpiracao();
    this.displayDialog = true;
  }

  copyQrCodePix() {
    navigator.clipboard.writeText(this.qrCodePix).then(() => {
      //console.log('Chave PIX copiada para a área de transferência!');
    }).catch(err => {
      //console.error('Erro ao copiar a chave PIX: ', err);
    });
  }

  public updateQuantidade(evento: any, index: number) {
    const quantidade = Number(evento.target.value);
    if (quantidade <= 1) return;
    this.listaDeIngressos[index].quantidade = quantidade;
    this.alteraValorTotal();
  }

  calcularExpiracao() {
    let dataCompraDate = new Date(this.dataCompra);
    let dataCompraMs = dataCompraDate.getTime();
    let expiracaoMs = dataCompraMs + (this.expiracaoEmSegundos * 1000);
    this.expiracao = new Date(expiracaoMs);
  }

  abrirModalConfirmacao() {
    this.ingressosSelecionados = this.listaDeIngressos.filter(ingresso => ingresso.ativo);
    this.displayConfirmationDialog = true;
  }

  private prepararDadosVenda() {
    return {
      solicitacaoIngressoQuantidade: this.ingressosSelecionados.map(ingresso => ({
        ingressoId: ingresso.id,
        quantidade: ingresso.quantidade
      }))
    };
  }

  async confirmarCompra() {
    this.toastService.showInfo("Aguarde", "Aguarde, estamos gerando sua compra", "bc")
    const dadosVenda = this.prepararDadosVenda();
    const [response, error] = await tryRunPromise(this.service.criarVenda(dadosVenda));
    if (error) {
      const erroResponse = error as HttpErrorResponse;
      this.toastService.showError('Erro ao gerar compra. ', erroResponse.error.mensagem);
      if (erroResponse.error?.erros) {
        erroResponse.error.erros.forEach((element: any) => {
          this.toastService.showError(element.key, element.message);
        });
      }
      return;
    }
    this.toastService.clear();
    if (response) {
      this.toastService.showSuccess("Sucesso!", "Compra Gerada com sucesso!");
      const dataAtual = new Date().toISOString();
      this.abrirModalPix(response.dados.pixCopiaECola, response.dados.expiracao, response.dados.valor, dataAtual)
    }
    this.displayConfirmationDialog = false;
  }
}