import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { ImpressaoIngressoComponent } from './pages/impressao-ingresso/impressao-ingresso.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'imprimir/:id', component: ImpressaoIngressoComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
