import { ChangeDetectionStrategy, Component, Input, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

@Pipe({
    name: 'shimmerHeight',
    standalone: true
})
export class ShimmerHeightPipe implements PipeTransform
{
    transform(type: ShimmerTypes, width: string, height: string): string
    {
        if ([ShimmerTypes.CIRCLE, ShimmerTypes.SQUARE].includes(type))
        {
            return width;
        } else
        {
            return height;
        }
    }
}

@Pipe({
    name: 'shimmerBorderRadius',
    standalone: true
})
export class ShimmerBorderRadiusPipe implements PipeTransform
{
    transform(type: ShimmerTypes, rounded = false): string
    {
        if (rounded)
        {
            return '3rem';
        } else
        {
            switch (type)
            {
                case ShimmerTypes.CIRCLE:
                    return '50%';
                case ShimmerTypes.SQUARE:
                    return '0px';
                default:
                    return '0px';
            }
        }
    }
}

export enum ShimmerTypes
{
    LINE = 'line',
    SQUARE = 'square',
    CIRCLE = 'circle',
}

@Component({
    selector: 'app-shimmer',
    standalone: true,
    imports: [CommonModule, ShimmerHeightPipe, ShimmerBorderRadiusPipe],
    templateUrl: './shimmer.component.html',
    styleUrls: ['./shimmer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShimmerComponent
{
    @Input() type: ShimmerTypes = ShimmerTypes.LINE;
    @Input() width = '100%';
    @Input() height = '12px';
    @Input() duration = '1s';
    @Input() rounded = false;
}
