import { signal } from "@angular/core";
import { TableBaseModel } from "./table-base.model";
import { ListaPaginada } from "./lista-paginada.model";
import { HttpErrorResponse } from "@angular/common/http";
import { TabeBaseService } from "./table-base.service";
import { tryRunPromise } from "../functions";
import { Pesquisa } from "./pesquisa.model";

export class DataSource<T extends TableBaseModel>
{
    public data = signal<ListaPaginada<T> | null>(null);
    public numberOfRows = signal<number>(5);
    public isLoading = signal<boolean>(false);
    public hasError = signal<HttpErrorResponse | null>(null);
    public selectedItem!: T | null;
    public pesquisa: Pesquisa;
    public rowsPerPage: number[] = [5, 10, 20, 30];

    constructor(private service: TabeBaseService<T>)
    {
        this.pesquisa = {numeroPagina: 1, tamanhoPagina: 5}
    }


    public async loadData(feiraId?: string): Promise<void>
    {
        this.hasError.set(null);
        this.isLoading.set(true);
        const [response, error] = await tryRunPromise<ListaPaginada<T>>(this.service.loadData(this.pesquisa, feiraId));
        this.isLoading.set(false);

        if (error)
        {
            this.hasError.set(error as HttpErrorResponse);
            return;
        }

        if (response)
            this.data.set(response);
    }

    public updateNumberRows(newValue: number)
    {
        this.numberOfRows.set(newValue);
    }

    public removeItem(item: T)
    {
        const indexToRemove = this.data()?.itens?.findIndex(i => i.id == item.id);

        if (!indexToRemove)
            return;

        this.data()?.itens?.splice(indexToRemove, 1);
    }

    public addItemOnTop(item: T)
    {
        this.data()?.itens?.unshift(item);
    }

    public addItemOnBottom(item: T)
    {
        this.data()?.itens?.push(item);
    }

    public updateItem(item: T)
    {
        this.data()?.itens?.map(i =>
        {
            if (i.id === item.id)
                Object.assign(i, item)
        });
    }

    public onPageChange(){

    }
}