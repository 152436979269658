import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom, of } from 'rxjs';
import { ListaPaginada } from 'src/app/shared/models/lista-paginada.model';
import { Pesquisa } from 'src/app/shared/models/pesquisa.model';
import { TabeBaseService } from 'src/app/shared/models/table-base.service';
import { environment } from 'src/environments/environment';
import { API_DASHBOARD_ROUTES } from './api/api-routes';
import { IngressosResponse, ListaVendasResponse } from './api/response/buscar-ingresso.response';
import { VendasResponse } from './api/response/buscar-venda.response';
import { ListarIngressoResponse } from './api/response/Listar-ingresso.response';

@Injectable()
export class DashboardService implements TabeBaseService<any> {

  private http = inject(HttpClient);
  loadData(pesquisa: Pesquisa, feiraId?: string | undefined): Promise<ListaPaginada<VendasResponse>> {
    return this.listarVendas(pesquisa, feiraId!);;
  }

  updateItem() {
    throw new Error('Method not implemented.');
  }
  removeItem() {
    throw new Error('Method not implemented.');
  }

  public async listarVendas(pesquisa: Pesquisa, feiraId: string): Promise<ListaPaginada<VendasResponse>> {
    const apiUrl = environment.api + API_DASHBOARD_ROUTES.buscarVendas();
    let params = new HttpParams();
    params = params.append("NumeroPagina", pesquisa.numeroPagina);
    params = params.append("TamanhoPagina", pesquisa.tamanhoPagina);
    //if (pesquisa.ascDes)
      params = params.append("AscDesc", -1);
    if (pesquisa.nomeColuna)
      params = params.append("NomeColuna", pesquisa.nomeColuna);
    if (pesquisa.textoPesquisado)
      params = params.append("TextoPesquisado", pesquisa.textoPesquisado);
    return await lastValueFrom(this.http.get<ListaPaginada<VendasResponse>>(apiUrl, { params }));;
  }
  
  public async listarTodasVendasIngressosPorUsuario(pesquisa: Pesquisa): Promise<ListaPaginada<ListarIngressoResponse>> {
    const apiUrl = environment.api + API_DASHBOARD_ROUTES.buscarVendas();
    let params = new HttpParams();
    params = params.append("NumeroPagina", pesquisa.numeroPagina);
    params = params.append("TamanhoPagina", pesquisa.tamanhoPagina);
    //if (pesquisa.ascDes)
      params = params.append("AscDesc", -1);
    if (pesquisa.textoPesquisado)
      params = params.append("TextoPesquisado", pesquisa.textoPesquisado);
    return await lastValueFrom(this.http.get<ListaPaginada<ListarIngressoResponse>>(apiUrl, { params }));;
  }

  public async buscarIngressos(): Promise<IngressosResponse[]> {
    const apiUrl = environment.api + API_DASHBOARD_ROUTES.ingressos();
    return await lastValueFrom(this.http.get<IngressosResponse[]>(apiUrl))
  } 

  public async criarVenda(request: any): Promise<any> {
    const apiUrl = environment.api + API_DASHBOARD_ROUTES.Vendas();
    return await lastValueFrom(this.http.post<any>(apiUrl, request));
  }
}